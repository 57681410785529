<template>
  <section id="sesiones">
    <v-container>
      <h1 class="titulo text-uppercase mb-5">Nuestros masajes</h1>
      <p class="textos">
        Masajes tántricos fusionados con otras prácticas de canalización de
        energía sexual.
      </p>
      <v-row class="justify-center">
        <v-col cols="6" md="auto">
          <v-card class="cont_video">
            <video
              src="../assets/videos/card_tantrica.mp4"
              playsinline
              autoplay
              muted
              loop
            ></video>
            <div
              class="cont_elementos d-flex flex-column justify-center align-center pa-3 pa-sm-5"
              @click="toggleMasaje('tantrica')"
            >
              <div class="cont_imagen d-flex justify-center align-center">
                <img src="../assets/sesiones/tantrico.svg" alt="" />
              </div>
              <p class="enlace text-center">Tántrica</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="tantrica">
          <v-card class="cont_video_seccion">
            <video
              src="../assets/videos/tantric_web.mp4"
              playsinline
              autoplay
              muted
              loop
            ></video>
            <v-container
              class="cont_elementos text-justify d-flex flex-column justity-center align-start pa-5 pa-lg-12"
            >
              <h1 class="titulo">Tántrica</h1>
              <p class="textos">
                Tendrás un tiempo meditativo y energético, conectarás tu mente
                con tu cuerpo mediante un masaje relajante fusionado con nuestra
                técnica tántrica, integrando instrumentos de armonización,
                respiraciones profundas y de posturas tántricas para lograr
                equilibrar la energía kundalini.
              </p>
              <v-btn
                size="large"
                class="boton mb-3"
                @click="informacion = !informacion"
              >
                Información
              </v-btn>
              <v-btn
                size="large"
                class="boton mb-3"
                @click="precios = !precios"
              >
                Precios
              </v-btn>
              <v-btn size="large" class="boton mb-3">
                <a
                  class="enlace"
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=524424902457&text=Hola%20necesito%20información%20sobre%20sesión%20Tántrica!"
                  >Agendar una cita</a
                >
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="6" md="auto">
          <v-card class="cont_video">
            <video
              src="../assets/videos/card_erotica.mp4"
              playsinline
              autoplay
              muted
              loop
            ></video>
            <div
              class="cont_elementos d-flex flex-column justify-center align-center pa-3 pa-sm-5"
              @click="toggleMasaje('erotico')"
            >
              <div class="cont_imagen d-flex justify-center align-center">
                <img src="../assets/sesiones/erotico.svg" alt="" />
              </div>
              <p class="enlace text-center">Erótico</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="erotico">
          <v-card class="cont_video_seccion">
            <video
              src="../assets/videos/cont_erotico.mp4"
              playsinline
              autoplay
              muted
              loop
            ></video>
            <v-container
              class="cont_elementos text-justify d-flex flex-column justity-center align-start pa-5 pa-lg-12"
            >
              <h1 class="titulo">Tántrico Erótico</h1>
              <p class="textos">
                Una secuencia para lograr un estado evolutivo.
              </p>
              <p class="textos">
                Adentrará a un trance de relajación
                físico y mental, se estimulará su
                cuerpo con nuestro masaje tántrico
                que es sutil y reconfortante para
                transmutar al masaje erótico, que
                es sensual y seductor, finalizando
                con un estimulante masaje lingam.
              </p>
              <v-btn
                size="large"
                class="boton mb-3"
                @click="informacion = !informacion"
              >
                Información
              </v-btn>
              <v-btn
                size="large"
                class="boton mb-3"
                @click="precios = !precios"
              >
                Precios
              </v-btn>
              <v-btn size="large" class="boton mb-3">
                <a
                  class="enlace"
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=524424902457&text=Hola%20necesito%20información%20sobre%20sesión%20Tántrico%20Erótico!"
                  >Agendar una cita</a
                >
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="6" md="auto">
          <v-card class="cont_video">
            <video
              src="../assets/videos/card_sensorial.mp4"
              playsinline
              autoplay
              muted
              loop
            ></video>
            <div
              class="cont_elementos d-flex flex-column justify-center align-center pa-3 pa-sm-5"
              @click="toggleMasaje('sensorial')"
            >
              <div class="cont_imagen d-flex justify-center align-center">
                <img src="../assets/sesiones/sensorial.svg" alt="" />
              </div>
              <p class="enlace text-center">Sensorial</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="sensorial">
          <v-card class="cont_video_seccion">
            <video
              src="../assets/videos/cont_sensorial.mp4"
              playsinline
              autoplay
              muted
              loop
            ></video>
            <v-container
              class="cont_elementos text-justify d-flex flex-column justity-center align-start pa-5 pa-lg-12"
            >
              <h1 class="titulo">Tántrico Sensorial</h1>
              <p class="textos">
                Conectaré tus cinco sentidos a través de la estimulación de
                estos para potencializar y reconocerlos.
              </p>
              <p class="textos">
                Conectarás tus cinco sentidos a
                través de la estimulación de estos
                para potencializar o reconocerlos.
                Llegarás a un estado de relajación
                mental y físico, a través de un
                masaje relajante, se integrarán
                elementos para la estimulación
                auditiva, visual, tacto, olfativa y
                gustativa en un espacio sensual y
                seductor.
              </p>
              <v-btn
                size="large"
                class="boton mb-3"
                @click="informacion = !informacion"
              >
                Información
              </v-btn>
              <v-btn
                size="large"
                class="boton mb-3"
                @click="precios = !precios"
              >
                Precios
              </v-btn>
              <v-btn size="large" class="boton mb-3">
                <a
                  class="enlace"
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=524424902457&text=Hola%20necesito%20información%20sobre%20sesión%20Tántrico%20Sensorial!"
                  >Agendar una cita</a
                >
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="6" md="auto">
          <v-card class="cont_video">
            <video
              src="../assets/videos/card_pada.mp4"
              playsinline
              autoplay
              muted
              loop
            ></video>
            <div
              class="cont_elementos d-flex flex-column justify-center align-center pa-3 pa-sm-5"
              @click="toggleMasaje('pada')"
            >
              <div class="cont_imagen d-flex justify-center align-center">
                <img src="../assets/sesiones/pada.svg" alt="" />
              </div>
              <p class="text-center enlace">Abhyanga Pada</p>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="pada">
          <v-card class="cont_video_seccion">
            <video
              src="../assets/videos/cont_pada.mp4"
              playsinline
              autoplay
              muted
              loop
            ></video>
            <v-container
              class="cont_elementos text-justify d-flex flex-column justity-center align-start pa-5 pa-lg-12"
            >
              <h1 class="titulo">Abhyanga Pada</h1>
              <p class="textos">
                Se libera la tensión corporal
                ejerciendo presión con los pies del
                terapeuta.

              </p>
              <p class="textos">
                Un masaje relajante y estimulante
                acompañados de aceites cálidos y
                medicinales,se ejerce presión en
                todo el cuerpo incluyendo el área
                genital con un suave masaje en un
                espacio sensual y cálido.

              </p>
              <v-btn
                size="large"
                class="boton mb-3"
                @click="informacion = !informacion"
              >
                Información
              </v-btn>
              <v-btn
                size="large"
                class="boton mb-3"
                @click="precios = !precios"
              >
                Precios
              </v-btn>
              <v-btn size="large" class="boton mb-3">
                <a
                  class="enlace"
                  target="_blank"
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=524424902457&text=Hola%20necesito%20información%20sobre%20sesión%20Abhyanga%20Pada!"
                  >Agendar una cita</a
                >
              </v-btn>
            </v-container>
          </v-card>
        </v-col>
        <!-- <v-col cols="6" sm="4" md="auto">
          <v-card class="cont_video">
            <video
              src="../assets/videos/card_bdsm.mp4"
              playsinline
              autoplay
              muted
              loop
            ></video>
            <div
              class="cont_elementos d-flex flex-column justify-center align-center pa-3 pa-sm-5"
              @click="toggleMasaje('bdsm')"
            >
              <div class="cont_imagen d-flex justify-center align-center">
                <img src="../assets/sesiones/bdsm.svg" alt="" />
              </div>
              <p class="text-center enlace">BDSM</p>
            </div>
          </v-card>
        </v-col> -->
        
      </v-row>
      <InformesComp v-model="informacion" />
      <PreciosComp v-model="precios" />
    </v-container>
  </section>
</template>

<style lang="scss">
#sesiones {
  width: 100%;

  .cont_video {
    width: 100%;
    height: 30vh;
    position: relative;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      object-fit: cover;
      transform: scale(1.1);
    }

    .cont_elementos {
      width: 100%;
      height: 100%;
      position: relative;

      .cont_imagen {
        width: 40%;
        height: auto;
        margin-bottom: 5%;

        img {
          width: 100%;
        }
      }
    }
  }
  .cont_video:hover {
    cursor: pointer;
  }
  .cont_video_seccion {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      object-fit: cover;
      transform: scale(1.1);
    }

    .cont_elementos {
      width: 100%;
      height: 85vh;
      position: relative;
    }
  }
}
</style>

<script>
import InformesComp from "@/components/InformesComp.vue";
import PreciosComp from "@/components/PreciosComp.vue";

export default {
  components: {
    InformesComp,
    PreciosComp,
  },
  data() {
    return {
      tantrica: false,
      erotico: false,
      sensorial: false,
      pada: false,
      bdsm: false,
      informacion: false,
      precios: false,
    };
  },
  methods: {
    toggleMasaje: function (tipoMasaje) {
      switch (tipoMasaje) {
        case "tantrica":
          this.tantrica = !this.tantrica;
          this.erotico = false;
          this.sensorial = false;
          this.pada = false;
          this.bdsm = false;
          break;

        case "erotico":
          this.tantrica = false;
          this.erotico = !this.erotico;
          this.sensorial = false;
          this.pada = false;
          this.bdsm = false;
          break;

        case "sensorial":
          this.tantrica = false;
          this.erotico = false;
          this.sensorial = !this.sensorial;
          this.pada = false;
          this.bdsm = false;
          break;

        case "pada":
          this.tantrica = false;
          this.erotico = false;
          this.sensorial = false;
          this.pada = !this.pada;
          this.bdsm = false;
          break;

        case "bdsm":
          this.tantrica = false;
          this.erotico = false;
          this.sensorial = false;
          this.pada = false;
          this.bdsm = !this.bdsm;
          break;

        default:
          this.tantrica = false;
          this.erotico = false;
          this.sensorial = false;
          this.pada = false;
          this.bdsm = false;
          break;
      }
    },
  },
};
</script>
