<template>
  <section id="section_404">
    <header class="navbar">
      <div class="logo mx-2">
        <router-link to="/home">
          <img
            class="logo_menu align-self-center"
            src="../assets/internal/logo_naranja.png"
          />
        </router-link>
      </div>

      <ul class="links">
        <li @click="cerrar" class="mx-3">
          <router-link class="enlace" to="/home">Inicio</router-link>
        </li>
        <li @click="cerrar" class="mx-3">
          <router-link class="enlace" to="/talleres">Talleres</router-link>
        </li>
        <div class="cont_redes d-flex justify-content-center">
          <div class="mx-2">
            <p class="enlace siguenos">Síguenos en:</p>
          </div>
          <div class="mx-2">
            <a
              class="enlace"
              target="_blank"
              href="https://twitter.com/Sxologa_Tantrik"
              ><i class="icono fa-brands fa-x-twitter"></i
            ></a>
          </div>
          <div class="mx-2">
            <a
              class="enlace"
              target="_blank"
              href="https://www.instagram.com/studio.tantrico/"
              ><v-icon class="icono">mdi-instagram</v-icon></a
            >
          </div>
          <div class="mx-2">
            <a
              class="enlace"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100091797198420"
              ><v-icon class="icono">mdi-facebook</v-icon></a
            >
          </div>
        </div>
      </ul>
      <div class="toggle mx-2" @click="abrir()">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>
    </header>

    <v-container class="contenedor text-center">
      <h1 class="titulo text-center mb-16">Página no encontrada</h1>
      <v-btn class="boton enlace" text outlined color="white" to="/home">
        <v-icon class="mx-2">mdi-arrow-left</v-icon> Regresar a inicio
      </v-btn>
    </v-container>
    <Footer-comp />
  </section>
</template>

<script>
import FooterComp from "@/components/FooterComp.vue";

export default {
  name: "HomeView",
  components: {
    FooterComp,
  },
  methods: {
    abrir: function () {
      const navbar = document.querySelector(".navbar");
      navbar.querySelector(".toggle").addEventListener("click", () => {
        navbar.classList.toggle("collapsed");
      });

      window.addEventListener("scroll", () => {
        let windowY = window.scrollY;
        let navbarHeight = document.querySelector(".navbar").offsetHeight;
        if (windowY > navbarHeight) navbar.classList.add("sticky");
        else navbar.classList.remove("sticky");
      });
    },
    cerrar: function () {
      const navbar = document.querySelector(".navbar");
      navbar.classList.remove("collapsed");
    },
  },
};
</script>

<style lang="scss">
#section_404 {
  height: 100vh !important;
  background: var(--negro);

  .contenedor {
    background: var(--gris);
    padding-top: 100px;
    padding-bottom: 80px;
  }
}
</style>
