import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import IntroComp from '../components/IntroComp.vue'
import TalleresView from '../views/TalleresView.vue'
import OtrosServiciosView from '@/views/OtrosServiciosView.vue'
import ContactoView from '../views/ContactoView.vue'
import NotFoundView from '../views/NotFoundView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'intro',
    component: IntroComp
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/talleres',
    name: 'talleres',
    component: TalleresView
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: ContactoView
  },
  {
    path: '/otros_servicios',
    name: 'otros_servicios',
    component: OtrosServiciosView
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFoundView
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
