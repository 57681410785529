<template>
  <v-dialog v-model="dialogVisible" width="auto" class="modal">
    <v-card class="pa-5 pa-lg-12" color="#000000">
      <h1 class="titulo text-uppercase mb-5">Información</h1>
      <p class="textos">○ Revisar la disponibilidad del espacio a reservar.</p>
      <p class="textos">
        ○ No se solicita dinero por anticipado solo puntualidad y seriedad a la
        hora reservada.
      </p>
      <p class="textos">
        ○ La sesión contratada se paga en el momento de su cita.
      </p>
      <p class="textos">
        ○ Métodos de pago: efectivo / tarjeta débito o crédito / transferencia.
      </p>
      <p class="textos">○ Horario de atención : lunes a sábado 10am a 10pm</p>
      <p class="textos">
        ○ Sesiones en su lugar de su mayor preferencia + $300 adicionales ,
        Gasto de traslados .
      </p>
      <v-card-actions>
        <v-btn size="large" class="boton mx-auto" @click="$emit('input', false)"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      dialogVisible: this.value, // Copia de la prop 'value' para modificar
    };
  },
  watch: {
    value(newVal) {
      this.dialogVisible = newVal; // Actualiza la copia cuando cambia la prop 'value'
    },
    dialogVisible(newVal) {
      this.$emit("input", newVal); // Emite el evento 'input' para cambiar la prop 'value'
    },
  },
};
</script>
