<template>
  <v-dialog v-model="preciosVisible" width="auto" class="modal">
    <v-card class="pa-5 pa-lg-12" color="#000000">
      <h1 class="titulo text-uppercase mb-5">Precios</h1>
      <v-row>
        <v-col cols="12" md="4" class="text-center">
          <h2 class="textos mb-2">Individual</h2>
          <p
            v-for="(individual, index) in individuales"
            :key="index"
            class="textos"
          >
            ○ {{ individual.duracion }} minutos - ${{ individual.precio }} mxn
          </p>
        </v-col>
        <v-col cols="12" md="4" class="text-center" v-if="showIndividualPrices">
          <h2 class="textos mb-2">A cuatro manos</h2>
          <p
            v-for="(cuatroMano, index) in cuatroManos"
            :key="index"
            class="textos"
          >
            ○ {{ cuatroMano.duracion }} minutos - ${{ cuatroMano.precio }} mxn
          </p>
        </v-col>
        <v-col cols="12" md="4" class="text-center" v-if="showIndividualPrices">
          <h2 class="textos mb-2">Pareja</h2>
          <p v-for="(pareja, index) in parejas" :key="index" class="textos">
            ○ {{ pareja.duracion }} minutos - ${{ pareja.precio }} mxn
          </p>
        </v-col>
      </v-row>
      <p class="text-uppercase text-center leyenda" v-if="showBdsmLeyend">
        "La sesión individual es impartida por la Directora de Studio Tántrico, es la única persona capacitada para esta experiencia"
      </p>
      <p class="text-uppercase text-center leyenda">
        "Tanto el prestador de servicio como el cliente, dan conocimiento de ser
        consensuado y sensato cada una de las sesiones a desempeñar"
      </p>
      <v-card-actions>
        <v-btn
          size="large"
          class="boton mx-auto"
          @click="$emit('input', false)"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    showIndividualPrices: {
      type: Boolean,
      default: true,
    },
    showBdsmLeyend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      preciosVisible: this.value, // Copia de la prop 'value' para modificar

      individuales: [
        { duracion: 60, precio: 2200 },
        { duracion: 90, precio: 3000 },
        { duracion: 120, precio: 3800 },
      ],
      cuatroManos: [
        { duracion: 60, precio: 3300 },
        { duracion: 90, precio: 4100 },
        { duracion: 120, precio: 4800 },
      ],
      parejas: [
        { duracion: 60, precio: 4500 },
        { duracion: 90, precio: 5300 },
        { duracion: 120, precio: 6100 },
      ],
    };
  },
  watch: {
    value(newVal) {
      this.preciosVisible = newVal; // Actualiza la copia cuando cambia la prop 'value'
    },
    preciosVisible(newVal) {
      this.$emit("input2", newVal); // Emite el evento 'input' para cambiar la prop 'value'
    },
  },
};
</script>
