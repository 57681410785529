<template>
  <section id="contacto">
    <header class="navbar">
      <div class="logo mx-2">
        <router-link to="/home">
          <img
            class="logo_menu align-self-center"
            src="../assets/internal/logo_naranja.png"
          />
        </router-link>
      </div>

      <ul class="links">
        <li @click="cerrar" class="mx-3">
          <router-link class="enlace" to="/home">Inicio</router-link>
        </li>
        <li @click="cerrar" class="mx-3">
          <router-link class="enlace" to="/talleres">Talleres</router-link>
        </li>
        <li @click="cerrar" class="mx-3">
          <router-link class="enlace" to="/contacto">Contacto</router-link>
        </li>
      </ul>
      <div class="toggle mx-2" @click="abrir()">
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>
    </header>

    <v-container class="cont_superior px-12 text-center">
      <h1 class="titulo mb-5">Contacto</h1>
      <p class="textos">Agenda tu cita por whatsapp.</p>
      <v-btn size="large" class="boton mb-3">
        <a
          class="enlace"
          href="https://api.whatsapp.com/send?phone=524424902457&text=Hola%20me%20gustaría%20agendar%20una%20cita%20!"
          >Agendar cita</a
        >
      </v-btn>
      <p class="textos">Síguenos en:</p>
      <div class="cont_redes d-flex justify-center mx-auto mb-5">
        <div class="mx-2">
          <a
            class="enlace"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/Sxologa_Tantrik"
            ><i class="icono fa-brands fa-x-twitter"></i
          ></a>
        </div>
        <div class="mx-2">
          <a
            class="enlace"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/studio.tantrico/"
            ><v-icon class="icono">mdi-instagram</v-icon></a
          >
        </div>
        <div class="mx-2">
          <a
            class="enlace"
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/profile.php?id=100091797198420"
            ><v-icon class="icono">mdi-facebook</v-icon></a
          >
        </div>
        <div class="mx-2">
          <a
            class="enlace"
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=524424902457&text=Hola%20necesito%20información%20sobre%20sesiones%20!"
            ><v-icon class="icono">mdi-whatsapp</v-icon></a
          >
        </div>
      </div>
    </v-container>
    <Footer-comp />
  </section>
</template>

<script>
import FooterComp from "@/components/FooterComp.vue";

export default {
  name: "HomeView",
  components: {
    FooterComp,
  },
  methods: {
    abrir: function () {
      const navbar = document.querySelector(".navbar");
      navbar.querySelector(".toggle").addEventListener("click", () => {
        navbar.classList.toggle("collapsed");
      });

      window.addEventListener("scroll", () => {
        let windowY = window.scrollY;
        let navbarHeight = document.querySelector(".navbar").offsetHeight;
        if (windowY > navbarHeight) navbar.classList.add("sticky");
        else navbar.classList.remove("sticky");
      });
    },
    cerrar: function () {
      const navbar = document.querySelector(".navbar");
      navbar.classList.remove("collapsed");
    },
  },
};
</script>

<style lang="scss">
#contacto {
  width: 100%;
  height: 100vh !important;
  background: var(--negro);

  .cont_superior {
    background: var(--gris);
    padding-top: 80px;

    .cont_redes {
      .enlace {
        color: var(--blanco);

        .icono {
          font-size: 2.5rem;
          color: var(--blanco);
        }

        .icono:hover {
          cursor: pointer;
          color: var(--naranja) !important;
        }
      }

      .enlace:hover {
        cursor: pointer;
        color: var(--naranja) !important;
      }
    }
  }
}
</style>
