<template>
  <section id="bdsm">
    <v-container>
      <h1 class="titulo text-uppercase mb-5">BDSM</h1>
      <div class="d-flex flex-column flex-lg-row">
        <div class="cont_video px-4 mx-auto">
          <video
            src="../assets/videos/cont_bdsm.mp4"
            playsinline
            autoplay
            muted
            loop
            class="rounded-lg"
          ></video>
        </div>
        <div>
          <p class="textos">
            Una sesión liberadora para personas que exploran su sexualidad
            tocando su límite mental y físico.
          </p>
          <p class="textos">
            Sesión 100% personalizada, cerraré la sesión con un masaje tántrico
            para armonizar tu integridad.
          </p>
          <p class="textos">
            “Todas las prácticas del BDSM son Sensato, Seguro y Consensuado"
          </p>
          <v-col cols="auto">
            <v-btn class="boton" @click="informacion = !informacion">
              Información
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn class="boton" @click="precios = !precios"> Precios </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn class="boton mb-3">
              <a
                class="enlace"
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=524424902457&text=Hola%20necesito%20información%20sobre%20sesión%20BDSM%20!"
                >Agendar una cita</a
              >
            </v-btn>
          </v-col>
        </div>
      </div>
      <InformesComp v-model="informacion" />
      <PreciosComp v-model="precios" :showIndividualPrices="false" :showBdsmLeyend="true" />
    </v-container>
  </section>
</template>

<script>
import InformesComp from "@/components/InformesComp.vue";
import PreciosComp from "@/components/PreciosComp.vue";

export default {
  components: {
    InformesComp,
    PreciosComp,
  },
  data() {
    return {
      informacion: false,
      precios: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
#bdsm {
  .cont_video {
    width: 100%;

    video {
      width: 100%;
    }
  }
}
</style>
